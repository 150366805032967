import { HStack, Text } from '@chakra-ui/react';
import Avatar from '@jurnee/common/src/components/Avatar';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getUserLabel, sortByUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { RemovableItemsList } from 'src/components/RemovableItemsList';
import { RemovableItem } from 'src/components/RemovableItemsList/RemovableItem';

interface Props {
  users: UserDetails[];
  userIds: number[];
  onRemoveUser(user: UserDetails): void;
}

interface UserProps {
  user: UserDetails;
  onRemoveUser(user: UserDetails): void;
}

function RemovableUser({ user, onRemoveUser }: UserProps) {
  return (
    <RemovableItem key={user.id} onRemove={() => onRemoveUser(user)}>
      <HStack w="100%" spacing="10px">
        <Avatar user={user} borderRadius={4} />
        <Text>{getUserLabel(user)}</Text>
      </HStack>
    </RemovableItem>
  );
}

export function RemovableUsersList({ users, userIds, onRemoveUser }: Props) {
  if (userIds.length === 0) {
    return null;
  }

  const removableUsers = useMemo(
    () => sortByUserLabel(userIds.map(userId => users.find(user => userId === user.id))),
    [users, userIds]
  );

  return (
    <RemovableItemsList>
      {
        removableUsers.map(user =>
          <RemovableUser
            key={user.id}
            user={user}
            onRemoveUser={onRemoveUser}
          />
        )
      }
    </RemovableItemsList>
  );
}