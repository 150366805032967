import { BookingRelationshipsEntity, BookingsSearchParams, BookingsSearchQuery } from '@jurnee/common/src/entities/Booking';
import { DEFAULT_UTC_TIMEZONE } from '../entities/Address';
import ApprovalProcessEntity from '../entities/ApprovalProcess';
import { ApprovalResponse } from '../entities/ApprovalResponse';
import BookingEntity, { BookingDetails, BookingExport, BookingJSON, BookingRelationshipsJSON, BookingStatus } from '../entities/Booking';
import { BookingInvoice } from '../entities/BookingInvoice';
import { BookingOrganizerJSON } from '../entities/BookingOrganizer';
import ExternalCostEntity, { ExternalCostJSON } from '../entities/ExternalCost';
import { Quote } from '../entities/Quote';
import UserEntity, { UserJSON } from '../entities/User';
import { isApprovalProcessReviewer, isApprovalProcessUser } from './approvalProcesses';
import { hasAnApprovalRequestApproved, hasInitialApprovalResponses } from './approvalRequests';
import { sortByDate } from './arrays';
import { areAllBookingsInvoicesPaidByCard, hasCommitment, hasQuotePending, hasSetupIntent, haveAllASetupIntent } from './bookingInvoices';
import { isRegistered } from './bookingParticipants';
import { getBookingItemImagePath, getItemTimezone, hasConfirmedStatus, hasFinishedStatus, hasInitialStatus, hasPartner, hasProcessingStatus } from './bookingsItems';
import { getCdnImageUrl } from './core';
import { isDatePast } from './dates';
import { hasAPropositionsGroupOpen, hasAPropositionsGroupWaitingPropososals } from './propositionsGroups';
import { areAllQuotesSigned } from './quotes';
import { isEmpty } from './strings';
import { getUserLabel } from './user';

export const PLACEHOLDER_PATH = 'images/booking_placeholder.jpeg';

export function getBookingFrom({ externalCosts, bookingsItems }: Pick<BookingEntity, 'externalCosts' | 'bookingsItems'>): Date | null {
  let out: Date | null = null;

  for (const { from } of bookingsItems) {
    const date = new Date(from);

    if (out === null || date < out) {
      out = date;
    }
  }

  for (const { eventDate } of externalCosts) {
    const date = new Date(eventDate);

    if (out === null || date < out) {
      out = date;
    }
  }

  return out;
}

export function getBookingParticipants({ bookingsItems }: Pick<BookingEntity, 'bookingsItems'>): number {
  return bookingsItems.reduce((out, { participants }) => participants > out ? participants : out, 0);
}

export function isBookingInitial({ bookingsItems }: BookingJSON | BookingEntity): boolean {
  if (bookingsItems.length === 0) {
    return false;
  }

  return bookingsItems.every(hasInitialStatus);
}

export function hasPartners({ bookingsItems }: BookingEntity): boolean {
  if (bookingsItems.length === 0) {
    return false;
  }

  return bookingsItems.every(hasPartner);
}

export function hasMissingTimezone({ bookingsItems }: BookingEntity): boolean {
  if (bookingsItems.length === 0) {
    return true;
  }

  return bookingsItems.some((item) => getItemTimezone(item) === DEFAULT_UTC_TIMEZONE);
}

export function isBookingConfirmed({ bookingsItems }: BookingJSON | BookingEntity): boolean {
  if (bookingsItems.length === 0) {
    return false;
  }

  return bookingsItems.some(hasConfirmedStatus) && bookingsItems.every((bookingsItem) => hasConfirmedStatus(bookingsItem) || hasFinishedStatus(bookingsItem));
}

export function isBookingFinished({ bookingsItems }: BookingJSON | BookingEntity, externalCosts: (ExternalCostEntity | ExternalCostJSON)[]): boolean {
  if (bookingsItems.length === 0 && externalCosts.length === 0) {
    return false;
  }

  return bookingsItems.every(hasFinishedStatus) && externalCosts.every(({ eventDate }) => new Date(eventDate) < new Date());
}

export function isBookingCanceled({ canceledAt }: BookingJSON | BookingEntity): boolean {
  return !!canceledAt;
}

export function isBookingPassed({ bookingsItems }: BookingJSON): boolean {
  if (bookingsItems.length === 0) {
    return false;
  }

  return bookingsItems.every(({ to }) => isDatePast(new Date(to)));
}

export function getMainBookingRelation(booking: BookingDetails | BookingEntity | BookingJSON): (BookingDetails | BookingEntity | BookingJSON)['bookingsItems'][0] {
  return sortByDate(booking.bookingsItems, 'from')[0];
}

export function getBookingImagePath(booking: BookingJSON | BookingEntity | BookingDetails) {
  const bookingItem = getMainBookingRelation(booking);

  if (!bookingItem) {
    return PLACEHOLDER_PATH;
  }

  return getBookingItemImagePath(bookingItem);
}

export function getBookingImageUrl(booking: BookingJSON | BookingDetails) {
  const path = getBookingImagePath(booking);

  return getCdnImageUrl(path);
}

export function getBookingName(booking: BookingDetails | BookingJSON | BookingEntity) {
  if (!booking.name || isEmpty(booking.name)) {
    const date = (new Date(booking.createdAt)).toISOString().slice(0, 10);

    return `Booking ${date}`;
  }

  return booking.name.trim();
}

export function getMainOrganizerId(bookingOrganizers: BookingJSON['bookingsOrganizers']) {
  const sortedBookingOrganizers = [...bookingOrganizers].sort((a,b) =>
    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  return sortedBookingOrganizers.length > 0 ? sortedBookingOrganizers[0].organizerId : null;
}

export function getOrganizerLabel(bookingOrganizers: BookingOrganizerJSON[], users: UserJSON[]) {
  const organizerId = getMainOrganizerId(bookingOrganizers);
  const organizer = users.find(user => user.id === organizerId);

  return organizer ? getUserLabel(organizer) : '-';
}

export function isBookingProcessing({ bookingsItems }: BookingJSON | BookingEntity) {
  return bookingsItems.some(hasProcessingStatus);
}

export function isBookingCommitted({ approvalRequests, bookingsInvoices, quotes }: Pick<(BookingRelationshipsJSON | BookingRelationshipsEntity), 'approvalRequests' | 'bookingsInvoices' | 'quotes'>) {
  if (bookingsInvoices.length === 0) {
    return false;
  }

  return bookingsInvoices.every(bookingInvoice => {
    const currentQuotes = quotes.filter(({ providerInvoiceId }) => providerInvoiceId === bookingInvoice.providerInvoiceId);
    const currentApprovalRequests = approvalRequests.filter(({ providerInvoiceId }) => providerInvoiceId === bookingInvoice.providerInvoiceId);

    if (hasSetupIntent(bookingInvoice)) {
      return true;
    }

    return hasCommitment(bookingInvoice, currentQuotes, currentApprovalRequests);
  });
}

export function getBookingStatus(booking: BookingJSON | BookingEntity, relationships: Pick<(BookingRelationshipsJSON | BookingRelationshipsEntity), 'approvalRequests' | 'bookingsInvoices' | 'externalCosts' | 'propositionsGroups' | 'propositions' | 'quotes'>): BookingStatus {
  const { approvalRequests, bookingsInvoices, externalCosts, propositionsGroups, propositions, quotes } = relationships;

  if (isBookingCanceled(booking)) {
    return 'CANCELED';
  }

  if (isBookingFinished(booking, externalCosts)) {
    return 'FINISHED';
  }

  if (isBookingConfirmed(booking)) {
    return 'CONFIRMED';
  }

  if (hasAnApprovalRequestApproved(approvalRequests)) {
    return 'CONFIRMING_AVAILABILITY';
  }

  if (areAllQuotesSigned(quotes)) {
    return 'CONFIRMING_AVAILABILITY';
  }

  if (haveAllASetupIntent(bookingsInvoices)) {
    return 'CONFIRMING_AVAILABILITY';
  }

  if (approvalRequests.some(hasInitialApprovalResponses)) {
    return 'AWAITING_APPROVAL';
  }

  if (hasQuotePending(quotes)) {
    return 'AWAITING_QUOTE';
  }

  if (hasAPropositionsGroupOpen(propositionsGroups)) {
    return 'PROPOSITION_READY';
  }

  if (hasAPropositionsGroupWaitingPropososals(propositionsGroups, propositions)) {
    return 'PROCESSING';
  }

  if (isBookingProcessing(booking)) {
    return 'PROCESSING';
  }

  if (booking.bookingsItems.length === 0 && externalCosts.length > 0) {
    return 'EXTERNAL_COSTS';
  }

  return 'DRAFT';
}

export function getCommitmentType(booking: Pick<BookingExport | BookingRelationshipsJSON, 'quotes' | 'approvalRequests' | 'bookingsInvoices'>) {
  if (areAllQuotesSigned(booking.quotes)) {
    return 'QUOTE';
  }

  if (hasAnApprovalRequestApproved(booking.approvalRequests)) {
    return 'APPROVAL_REQUEST';
  }

  if (areAllBookingsInvoicesPaidByCard(booking.bookingsInvoices)) {
    return 'CREDIT_CARD';
  }

  return null;
}

export function getCommitmentDate(booking: Pick<BookingExport, 'quotes' | 'approvalRequests' | 'bookingsInvoices'>) {
  switch (getCommitmentType(booking)) {
  case 'QUOTE': {
    const quotes = booking.quotes as (Quote & { acceptedAt: Date })[];
    return sortByDate(quotes, 'acceptedAt', 'desc')[0].acceptedAt;
  }
  case 'CREDIT_CARD': {
    const bookingsInvoices = booking.bookingsInvoices as (BookingInvoice & { finalizedAt: Date })[];
    return sortByDate(bookingsInvoices, 'finalizedAt', 'desc')[0].finalizedAt;
  }
  case 'APPROVAL_REQUEST': {
    const approvalResponses = booking.approvalRequests.flatMap(({ approvalResponses }) => approvalResponses) as (ApprovalResponse & { approvedAt: Date })[];
    return sortByDate(approvalResponses, 'approvedAt', 'desc')[0].approvedAt;
  }
  default:
    return null;
  }
}

export function buildBookingsSearchParams(query: BookingsSearchQuery): BookingsSearchParams {
  const params: BookingsSearchParams = {};

  if (query.before) {
    params.before = Number(query.before);
  }

  if (query.after) {
    params.after = Number(query.after);
  }

  return params;
}

export function sortByBookingsItemsFrom<T extends BookingJSON>(list: T[]): T[] {
  return [...list].sort((a, b) => {
    const [bookingItemA] = sortByDate(a.bookingsItems, 'from');
    const [bookingItemB] = sortByDate(b.bookingsItems, 'from');

    return new Date(bookingItemA.from).getTime() - new Date(bookingItemB.from).getTime();
  });
}

function groupByTime<T extends BookingJSON>(list: T[], getDate: (date: Date) => Date): { [key: number]: T[] } {
  return [...list].reduce((map, booking) => {
    const [bookingItem] = sortByDate(booking.bookingsItems, 'from');

    if (!bookingItem) {
      return map;
    }

    const time = getDate(new Date(bookingItem.from)).getTime();

    if (Array.isArray(map[time])) {
      map[time].push(booking);
    } else {
      map[time] = [booking];
    }

    return map;
  }, {} as Record<number, T[]>);
}

export function groupByDay<T extends BookingJSON>(list: T[]): { [key: number]: T[] } {
  return groupByTime(list, (from) => {
    return new Date(from.getFullYear(), from.getMonth(), from.getDate());
  });
}

export function groupByMonth<T extends BookingJSON>(list: T[]): { [key: number]: T[] } {
  return groupByTime(list, (from) => {
    return new Date(from.getFullYear(), from.getMonth());
  });
}

export function isBookingOrganizer({ bookingsOrganizers }: Pick<BookingEntity, 'bookingsOrganizers'>, userId: UserEntity['id']) {
  return bookingsOrganizers.some(({ organizerId }) => organizerId === userId);
}

export function isSelfApprovalEnabled(booking: Pick<BookingEntity, 'bookingsOrganizers'>, approvalProcess: ApprovalProcessEntity, userId: UserEntity['id']) {
  if (!isBookingOrganizer(booking, userId)) {
    return false;
  }

  if (!isApprovalProcessReviewer(approvalProcess, userId)) {
    return false;
  }

  return isApprovalProcessUser(approvalProcess, userId);
}

export function filterRegisteredBookingsByUser(bookings: BookingEntity[], { email }: UserEntity) {
  return bookings.filter(({ budgetId, bookingsParticipants }) => {
    const isUserRegistered = bookingsParticipants.some(bookingParticipant =>
      bookingParticipant.participant.email === email && isRegistered(bookingParticipant)
    );

    return !budgetId && isUserRegistered;
  });
}

export function getOrganizerIds(booking: Pick<BookingEntity, 'bookingsOrganizers'>): number[] {
  return booking.bookingsOrganizers.map(bookingOrganizer => bookingOrganizer.organizerId);
}