import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Input, Select, Switch, Text, UseToastOptions } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { SearchSelect } from '@jurnee/common/src/components/Select';
import { ApprovalProcessUpsertBody } from '@jurnee/common/src/dtos/approvalProcesses';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApprovalProcessCreatePayload, ApprovalProcessUpdatePayload, createApprovalProcess, updateApprovalProcess } from 'src/store/approvalProcesses/approvalProcesses.thunks';
import { getEmployees } from 'src/store/employees/employees.thunks';
import { RootState } from 'src/store/state';
import { showToast } from 'src/store/toasts/toasts.thunks';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';
import { TrackEventOptions, trackEvent } from '../../store/analytics/analytics.thunks';
import { UsersList } from './UsersList';

interface OwnProps {
  approvalProcess?: ApprovalProcessJSON;
  children: React.ReactElement;
}

interface StateProps {
  approvalProcesses: RootState['approvalProcesses'];
  employees: RootState['employees'];
}

interface State {
  isOpen: boolean;
  approvalProcess: ApprovalProcessUpsertBody;
}

interface DispatchProps {
  createApprovalProcess(data: ApprovalProcessCreatePayload): void;
  getEmployees(): void;
  showToast(payload: UseToastOptions): void;
  trackEvent(opts: TrackEventOptions): void;
  updateApprovalProcess(data: ApprovalProcessUpdatePayload): void;
}

type Props = OwnProps & DispatchProps & StateProps & WithTranslation;

class EditApprovalProcessDrawer extends React.PureComponent<Props, State> {

  state: State = {
    isOpen: false,
    approvalProcess: this.getDefaultApprovalProcessState()
  };

  getDefaultApprovalProcessState(): State['approvalProcess'] {
    const { approvalProcess } = this.props;

    return {
      name: approvalProcess ? approvalProcess.name : '',
      type: approvalProcess ? approvalProcess.type : 'PARALLEL',
      processNumberRequired: approvalProcess ? approvalProcess.processNumberRequired : false,
      approverIds: approvalProcess ? approvalProcess.approvalRules.map(({ approver }) => approver.id) : [],
      userIds: approvalProcess ? approvalProcess.users.map(({ id }) => id) : [],
    };
  }

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onOpen = () => {
    this.props.getEmployees();

    this.setState({
      isOpen: true,
      approvalProcess: this.getDefaultApprovalProcessState()
    });

    this.props.trackEvent({
      name: 'opened_approval_process_drawer'
    });
  };

  update = () => {
    this.props.updateApprovalProcess({
      data: this.state.approvalProcess,
      id: this.props.approvalProcess.id,
      onError: (err) => {
        const title = err instanceof Error ? err.message : this.props.t('approval.drawer.toasts.update.error');
        this.props.showToast({ title, status: 'error' });
      },
      onSuccess: () => {
        this.onClose();
        this.props.showToast({ title: this.props.t('approval.drawer.toasts.update.success'), status: 'success' });
      }
    });
  };

  create = () => {
    this.props.createApprovalProcess({
      data: this.state.approvalProcess,
      onError: (err) => {
        const title = err instanceof Error ? err.message : this.props.t('approval.drawer.toasts.create.error');
        this.props.showToast({ title, status: 'error' });
      },
      onSuccess: () => {
        this.onClose();
        this.props.showToast({ title: this.props.t('approval.drawer.toasts.create.success'), status: 'success' });
      }
    });
  };

  onSave = () => {
    this.props.approvalProcess ? this.update() : this.create();
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;

    this.setState({
      ...this.state,
      approvalProcess: {
        ...this.state.approvalProcess,
        [name]: name === 'processNumberRequired' ? checked : value
      }
    });
  };

  onTypeChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      approvalProcess: {
        ...this.state.approvalProcess,
        type: target.value as ApprovalProcessJSON['type']
      }
    });
  };

  onAddApprover = ({ value }: { value: UserDetails }) => {
    this.setState({
      ...this.state,
      approvalProcess: {
        ...this.state.approvalProcess,
        approverIds: [ ...this.state.approvalProcess.approverIds, value.id ]
      }
    });
  };

  onRemoveApprover = (approverId: number) => {
    this.setState({
      ...this.state,
      approvalProcess: {
        ...this.state.approvalProcess,
        approverIds: this.state.approvalProcess.approverIds.filter((id) => approverId !== id)
      }
    });
  };

  onApproversOrderChange = (approverIds: number[]) => {
    this.setState({
      ...this.state,
      approvalProcess: {
        ...this.state.approvalProcess,
        approverIds
      }
    });
  };

  raiseApprovalProcessAlreadyAssociatedError = (user: UserDetails) => {
    const approvalProcess = this.props.approvalProcesses.list.find(approvalProcess => approvalProcess.id === user.approvalProcessId);

    return this.props.showToast({ title: this.props.t('approval.drawer.toasts.approvalProcessAlreadyAssociated.error', { userName: getUserLabel(user), approvalProcessName: approvalProcess.name }), status: 'error' });
  };

  onAddUser = ({ value }: { value: UserDetails }) => {
    if (this.props.approvalProcess && !this.props.approvalProcess.users.map(({ id }) => id).includes(value.id) && value.approvalProcessId) {
      return this.raiseApprovalProcessAlreadyAssociatedError(value);
    }

    if (!this.props.approvalProcess && value.approvalProcessId) {
      return this.raiseApprovalProcessAlreadyAssociatedError(value);
    }

    this.setState({
      ...this.state,
      approvalProcess: {
        ...this.state.approvalProcess,
        userIds: [ ...this.state.approvalProcess.userIds, value.id ]
      }
    });
  };

  onRemoveUser = (userId: number) => {
    this.setState({
      ...this.state,
      approvalProcess: {
        ...this.state.approvalProcess,
        userIds: this.state.approvalProcess.userIds.filter((id) => userId !== id)
      }
    });
  };

  get name() {
    return (
      <FormControl isRequired>
        <FormLabel>{this.props.t('approval.drawer.form.name.label')}</FormLabel>
        <Input size="sm" name="name" value={this.state.approvalProcess.name} onChange={this.onChange} autoFocus />
      </FormControl>
    );
  }

  get processNumberRequired() {
    return (
      <FormControl mt={5}>
        <FormLabel>{this.props.t('approval.drawer.form.processNumberRequired.label')}</FormLabel>
        <HStack>
          <Switch
            size="sm"
            id="processNumberRequired"
            name="processNumberRequired"
            defaultChecked={this.state.approvalProcess.processNumberRequired}
            onChange={this.onChange}
          />
          <Text>{this.props.t('approval.drawer.form.processNumberRequired.switchLabel')}</Text>
        </HStack>
      </FormControl>
    );
  }

  get type() {
    return (
      <FormControl mt={5}>
        <FormLabel>{this.props.t('approval.drawer.form.type.label')}</FormLabel>
        <Text mb={2} color="gray.400">{this.props.t(`approval.drawer.form.type.${this.state.approvalProcess.type}.description`)}</Text>

        <Select
          size="sm"
          bg="white"
          onChange={this.onTypeChange}
          defaultValue={this.state.approvalProcess.type}
        >
          <option value="PARALLEL">{this.props.t('approval.drawer.form.type.PARALLEL.label')}</option>
          <option value="SEQUENTIAL">{this.props.t('approval.drawer.form.type.SEQUENTIAL.label')}</option>
      </Select>
    </FormControl>
    )
  }

  getUsersOptions(userIds: number[]) {
    const usersOptions = this.props.employees.list
      .filter(({ id }) => !userIds.includes(id))
      .map(user => ({ label: getUserLabel(user), value: user }));

    return sortAlphabeticallyBy(usersOptions, 'label');
  }

  get approvers() {
    if (this.props.employees.status !== FETCH_STATUS.FETCHED) {
      return <Loader />;
    }

    return (
      <FormControl mt={5}>
        <FormLabel display="flex" justifyContent="space-between" mr={0} mb={0}>
          {this.props.t('approval.drawer.form.approvers.label')}
        </FormLabel>
        <Text mb={2} color="gray.400">{this.props.t('approval.drawer.form.approvers.description')}</Text>

        <Box className="react-select-small">
          <SearchSelect<UserDetails>
            options={this.getUsersOptions(this.state.approvalProcess.approverIds)}
            closeMenuOnSelect={true}
            menuPlacement="top"
            openMenuOnFocus={true}
            maxMenuHeight={200}
            placeholder={this.props.t('approval.drawer.form.approvers.placeholder')}
            value={null}
            onChange={this.onAddApprover}
          />
        </Box>
        {
          this.state.approvalProcess.approverIds.length > 0 &&
            <UsersList
              users={this.props.employees.list}
              userIds={this.state.approvalProcess.approverIds}
              onRemove={this.onRemoveApprover}
              onOrderChange={this.state.approvalProcess.type === 'SEQUENTIAL' && this.onApproversOrderChange}
            />
        }
      </FormControl>
    );
  }

  get users() {
    if (this.props.employees.status !== FETCH_STATUS.FETCHED) {
      return <Loader />;
    }

    return (
      <FormControl mt={5}>
        <FormLabel display="flex" justifyContent="space-between" mr={0}>
          {this.props.t('approval.drawer.form.users.label')}
        </FormLabel>
        <Text mb={2} color="gray.400">{this.props.t('approval.drawer.form.users.description')}</Text>

        <Box className="react-select-small">
          <SearchSelect<UserDetails>
            options={this.getUsersOptions(this.state.approvalProcess.userIds)}
            closeMenuOnSelect={true}
            menuPlacement="top"
            openMenuOnFocus={true}
            maxMenuHeight={200}
            placeholder={this.props.t('approval.drawer.form.users.placeholder')}
            value={null}
            onChange={this.onAddUser}
          />
        </Box>
        {
          this.state.approvalProcess.userIds.length > 0 &&
            <UsersList
              users={this.props.employees.list}
              userIds={this.state.approvalProcess.userIds}
              onRemove={this.onRemoveUser}
            />
        }
      </FormControl>
    );
  }

  get body() {
    return (
      <DrawerBody>
        {this.name}
        {this.processNumberRequired}
        {this.type}
        {this.approvers}
        {this.users}
      </DrawerBody>
    );
  }

  get header() {
    return (
      <DrawerHeader>
        {this.props.t('approval.drawer.title')}
      </DrawerHeader>
    );
  }

  get footer() {
    return (
      <DrawerFooter>
        <HStack justifyContent="space-between" w="100%">
          <SecondaryButton size="sm" colorScheme="pink" onClick={this.onClose}>
            {this.props.t('buttons.close', { ns: 'common' })}
          </SecondaryButton>
          <PrimaryButton size="sm" colorScheme="teal" onClick={this.onSave} isDisabled={this.state.approvalProcess.approverIds.length === 0}>
            {this.props.t('buttons.save', { ns: 'common' })}
          </PrimaryButton>
        </HStack>
      </DrawerFooter>
    );
  }

  render() {
    return (
      <>
        {React.cloneElement(this.props.children, { onClick: this.onOpen })}
        <Drawer isOpen={this.state.isOpen} onClose={this.onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            {this.header}
            {this.body}
            {this.footer}
          </DrawerContent>
        </Drawer>
      </>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    approvalProcesses: state.approvalProcesses,
    employees: state.employees
  };
}

const mapDispatchToProps: DispatchProps = {
  createApprovalProcess,
  getEmployees,
  showToast,
  trackEvent,
  updateApprovalProcess,
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('settings')(EditApprovalProcessDrawer));