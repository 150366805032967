import { Box, Button, Divider, HStack, Heading, Link, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { getCommitmentType, isBookingConfirmed } from '@jurnee/common/src/utils/bookings';
import { sanitize } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import ProcessNumber from 'src/components/Booking/ProcessNumber';
import EventSchedule from 'src/components/BookingDetails/EventSchedule';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import EntityCard from 'src/components/EntityCard';
import EventConfirmedModal from 'src/modals/EventConfirmedModal';
import { ApprovalResponses } from '../ApprovalResponses';
import { BookingDetailsStepper } from '../BookingDetailsStepper';
import Invoices from '../Invoices';
import Quotes from '../Quotes';

interface OwnProps {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  entity: EntityJSON;
}

type Props = OwnProps & WithTranslation;

class WaitingEventStep extends React.PureComponent<Props> {

  get bookingComment() {
    if (!this.props.booking.comment) {
      return null;
    }

    return (
      <Box w="100%" borderBottom="1px solid" borderColor="blue.50" pb={5}>
        <Heading size="sm">
          {this.props.t('steps.waitingEvent.jurneeComment')}
        </Heading>
        <Text
          mt={2}
          fontSize={14}
          sx={{ 'a': { textDecoration: 'underline' } }}
          dangerouslySetInnerHTML={{ __html: sanitize(this.props.booking.comment) }}
        />
      </Box>
    );
  }

  get reminderComment() {
    return (
      <Box w="100%" borderBottom="1px solid" borderColor="blue.50" pb={5}>
        <Heading size="sm">
          {this.props.t('steps.waitingEvent.reminder.allSet')}
        </Heading>
        <UnorderedList my={2}>
          <ListItem>{this.props.t('steps.waitingEvent.reminder.ensureParticipants')}</ListItem>
          <ListItem>{this.props.t('steps.waitingEvent.reminder.sendReminders')}</ListItem>
          <ListItem>{this.props.t('steps.waitingEvent.reminder.askParticipants')}</ListItem>
        </UnorderedList>
        <Text>
          {this.props.t('steps.waitingEvent.reminder.afterEvent')}
        </Text>
      </Box>
    );
  }

  get processNumber() {
    if (!this.props.booking.processNumber) {
      return null;
    }

    return <ProcessNumber
      label={this.props.t('processNumber')}
      processNumber={this.props.booking.processNumber}
    />;
  }

  get approvalResponses() {
    const [approvalRequest] = sortByDate(this.props.relationships.approvalRequests, 'createdAt', 'desc');

    return (
      <VStack w="100%" spacing={3} alignItems="flex-start">
        <Text lineHeight="16px" fontWeight={700}>{this.props.t('approvalResponses.label')}</Text>

        <ApprovalResponses approvalRequest={approvalRequest} users={this.props.relationships.users} />
      </VStack>
    );
  }

  get quotes() {
    return (
      <VStack w="100%" spacing={3} alignItems="flex-start">
        <Text lineHeight="16px" fontWeight={700}>{this.props.t('quotes.label')}</Text>

        <Quotes quotes={this.props.relationships.quotes} bookingsInvoices={this.props.relationships.bookingsInvoices} />
      </VStack>
    );
  }

  get commitment() {
    const commitmentType = getCommitmentType(this.props.relationships);

    switch (commitmentType) {
    case 'APPROVAL_REQUEST':
      return this.approvalResponses;
    case 'QUOTE':
      return this.quotes;
    default:
      return null;
    }
  }

  get invoices() {
    return (
      <VStack w="100%" spacing={3} alignItems="flex-start">
        <Text lineHeight="16px" fontWeight={700}>{this.props.t('invoices.label')}</Text>

        <Invoices bookingsInvoices={this.props.relationships.bookingsInvoices} />
      </VStack>
    );
  }

  get priceDetails() {
    return getBookingInvoicesTotalDetails(this.props.relationships.bookingsInvoices);
  }

  get eventConfirmedModal() {
    if (!isBookingConfirmed(this.props.booking)) {
      return null;
    }

    const date = sortByDate(this.props.booking.bookingsItems, 'from')[0].from;

    return <EventConfirmedModal date={date} />;
  }

  render() {
    return (
      <>
        {this.eventConfirmedModal}

        <HStack w="100%" spacing={5} alignItems="flex-start">
          <VStack w="100%" alignItems="flex-start" spacing={8}>
            <VStack w="100%" alignItems="flex-start" spacing={3}>
              <Heading size="md">{this.props.t('steps.waitingEvent.heading')}</Heading>

              <VStack w="100%" p={5} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4} spacing={5}>
                {this.bookingComment}
                {this.reminderComment}
                <Text w="100%" fontSize={14} color="gray.400">
                  <Trans
                    i18nKey='booking:helpText'
                    components={{ intercom: <Link color="black" id="intercom-launcher" /> }}
                  />
                </Text>
              </VStack>
            </VStack>

            <EventSchedule
              heading={this.props.t('schedule.heading')}
              bookingsItems={this.props.booking.bookingsItems}
              experiences={this.props.relationships.experiences}
              products={this.props.relationships.products}
              currency={this.props.relationships.bookingsInvoices[0].currency}
              editDisabled={true}
            />
          </VStack>

          <VStack w="100%" minW={380} maxW={380} alignItems="stretch" spacing={3}>
            <Heading size="md">{this.props.t('invoicing.heading')}</Heading>

            <Box bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
              <BookingDetailsStepper step="WAITING_EVENT" />

              <VStack p={5} spacing={5}>
                <EntityCard entity={this.props.entity} />

                {this.processNumber}

                {this.commitment}

                {this.invoices}

                <Divider />

                <BookingPriceDetailsCard
                  subtotal={this.priceDetails.subtotal}
                  totalTax={this.priceDetails.totalTax}
                  totalDiscount={this.priceDetails.totalDiscount}
                  total={this.priceDetails.total}
                  currency={this.priceDetails.currency}
                  showCurrencyWarning={false}
                />

                <Button
                  w="100%"
                  color="white"
                  bg="gray.400"
                  _hover={{ bg: 'gray.400', color: 'white' }}
                  _disabled={{ bg: 'gray.400', color: 'white' }}
                  cursor='default'
                  disabled
                >
                  {this.props.t('steps.waitingEvent.button')}
                </Button>
              </VStack>
            </Box>
          </VStack>
        </HStack>
      </>
    );
  }

}

export default withTranslation('booking')(WaitingEventStep);