import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { BudgetOrganizerJSON } from '@jurnee/common/src/entities/BudgetOrganizer';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { sortByUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RemovableItemsList } from 'src/components/RemovableItemsList';
import EmptyStateList from 'src/components/RemovableItemsList/EmptyStateList';
import { Organizer } from './Organizer';

interface Props {
  budget: BudgetJSON;
  users: UserDetails[];
  budgetOrganizers: Pick<BudgetOrganizerJSON, 'userId' | 'isOwner'>[];
  onRemove(user: UserDetails): void;
  onBudgetOrganizerChange(data: Pick<BudgetOrganizerJSON, 'userId' | 'isOwner'>): void;
}

export function OrganizersList({ budget, users, budgetOrganizers, onRemove, onBudgetOrganizerChange }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: `budgets.drawer.form.users.GLOBAL` });

  const organizers = useMemo(() =>
    budgetOrganizers.map(budgetOrganizer => ({ ...users.find(user => budgetOrganizer.userId === user.id), budgetOrganizer })),
    [budgetOrganizers, users]
  );

  if (budgetOrganizers.length === 0) {
    return <EmptyStateList description={t('noUsers')} />;
  }

  return (
    <RemovableItemsList>
      {sortByUserLabel(organizers).map(({ budgetOrganizer, ...user }) =>
        <Organizer
          key={user.id}
          budget={budget}
          user={user}
          budgetOrganizer={budgetOrganizer}
          onRemove={onRemove}
          onBudgetOrganizerChange={onBudgetOrganizerChange}
        />
      )}
    </RemovableItemsList>
  );
}