import { HStack, Text, VStack } from '@chakra-ui/react';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';
import { ApprovalResponseStatus } from '../../../../components/Status/ApprovalResponseStatus';

interface Props {
  approvalResponse: ApprovalResponseJSON;
  user: UserJSON;
}

const dateOptions = {
  displayTime: true,
  displayTimeZone: true,
  timeZone: getCurrentTimeZone()
};

export function ApprovalResponseCard({ approvalResponse, user }: Props) {
  const { t } = useTranslation('approvalRequests');

  const respondedAtLabel = t(`approvalResponse.${approvalResponse.status.toLowerCase()}At`);
  const date = approvalResponse.status === 'APPROVED' ? approvalResponse.approvedAt : approvalResponse.rejectedAt;
  const respondedAt = date ? formatDate(new Date(date), dateOptions) : '-'

  return (
    <VStack w="100%" spacing={4}>
      <HStack w="100%" justifyContent="space-between" h="10px">
        <Text fontWeight={700}>{t('approvalResponse.status')}</Text>
        <ApprovalResponseStatus status={approvalResponse.status} />
      </HStack>

      <HStack w="100%" justifyContent="space-between" h="10px">
        <Text fontWeight={700}>{t('approvalResponse.reviewer')}</Text>
        <Text color="gray.400">{getUserLabel(user)}</Text>
      </HStack>

      {
        ['APPROVED', 'REJECTED'].includes(approvalResponse.status) &&
          <HStack w="100%" justifyContent="space-between" h="10px">
            <Text fontWeight={700}>{respondedAtLabel}</Text>
            <Text color="gray.400">{respondedAt}</Text>
          </HStack>
      }

      {
        approvalResponse.comment &&
          <VStack w="100%" spacing={3} alignItems="flex-start">
            <Text fontWeight={700}>{t('approvalResponse.comment')}</Text>
            <Text color="gray.400">{approvalResponse.comment}</Text>
          </VStack>
      }
    </VStack>
  );
}